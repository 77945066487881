import { useState } from "react";

import './App.css';
import Logo from "./img/logo_toptainer.png";
import SearchIcon from "./img/search_icon.svg";
import Arrow from "./img/flecha.svg";
import StatusAIcon from "./img/ingreso_en_sucursal.png";
import StatusBIcon from "./img/en_proceso_de_carga.png";
import StatusCIcon from "./img/en_transito.png";
import StatusDIcon from "./img/en_proceso_de_descarga.png";
import StatusEIcon from "./img/en_bodega_destino.svg";
import StatusFIcon from "./img/entregado_a_cliente.png";
import Swal from 'sweetalert2';

function App() {

  function mes(numero) {
    switch (numero) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
      default: 
        return "";
    }
  }

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({ orden_transporte: "", rut: "" });
  const [data, setData] = useState({ });

  return (
    <div className="content">
      <div className="header">
        <img src={Logo} className="logo" alt="" />
      </div>
      <div className="content-body">
        <div className="row">
          <p className="p-title">SEGUIMIENTO DE TU ORDEN EN LÍNEA:</p>
        </div>
        <div className="row">
          <div className="col-40">
            <input 
              className="input-text" 
              type="number" 
              placeholder="Orden de transporte" 
              value={searchData.orden_transporte} 
              onChange={(evt) => { setSearchData({ ...searchData, orden_transporte: evt.target.value }) }} />
          </div>
          <div className="col-40">
            <input 
              className="input-text" 
              type="text" 
              placeholder="RUT (12.345.678-9)" 
              value={searchData.rut} 
              onChange={(evt) => { 

                let newRut = evt.target.value.toUpperCase().replace(/[^0-9K]/, "").replaceAll(".", "").replaceAll("-", "");

                if (newRut.length > 1) {
                  let enteros = newRut.substring(0, newRut.length - 1);
                  let dv = newRut.substring(newRut.length - 1);
                  newRut = "";

                  for (let i = 1; i <= enteros.length; i++) {
                    newRut = (i % 3 === 0 && enteros.length !== i ? "." : "") + enteros[enteros.length - i] + newRut;
                  }

                  newRut = newRut + "-" + dv;
                }

                setSearchData({ ...searchData, rut: newRut }) 
              }} />
          </div>
          <div className="col-20">
            <button className="search-button" onClick={() => {

              if (searchData.orden_transporte.length === 0) {
                Swal.fire({
                  icon: "error",
                  title: "¡Ocurrió un error!",
                  text: "Debe ingresar la orden de transporte a buscar."
                });
                return;
              }

              if (searchData.rut.length === 0) {
                Swal.fire({
                  icon: "error",
                  title: "¡Ocurrió un error!",
                  text: "Debe ingresar el rut del cliente de la orden de transporte a buscar a buscar."
                });
                return;
              }

              setLoading(true);

              fetch("https://us-central1-kimbo-327514.cloudfunctions.net/ToptainerSeguimiento/ordenes-transporte/orden-transporte", { 
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(searchData)
              })
              .then(response => {
                if (response.ok) {
                  response.json().then(responseData => {
                    setData(responseData.data);
                    setLoading(false);

                    if (!responseData.data.id_orden_transporte) {
                      Swal.fire({
                        icon: "error",
                        title: "¡Ocurrió un error!",
                        text: "No encontramos tu orden de transporte para el número y rut indicado, favor intentalo nuevamente."
                      });
                    }
                  })
                  .catch(error => {
                    Swal.fire({
                      icon: "error",
                      title: "¡Ocurrió un error!",
                      text: "No logramos completar tu solicitud, favor intentalo nuevamente."
                    });
                    setLoading(false);
                  })
                }
              })
              .catch(error => {
                Swal.fire({
                  icon: "error",
                  title: "¡Ocurrió un error!",
                  text: "No logramos completar tu solicitud, favor intentalo nuevamente."
                });
                setLoading(false);
              })
            }}>
              <img src={SearchIcon} className="search-icon" alt="" />
              Buscar...
            </button>
          </div>
        </div>
        {
          data.id_orden_transporte &&
          <>
            <br />
            <br />
            <div className="row">
              <div className="col-100">
                <p className="p-subtitle">
                  <img src={Arrow} className="right-arrow" alt="" />
                  ESTADO ORDEN DE TRANSPORTE N°{data.id_orden_transporte}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-1-6">
                <div className={`status-icon${data.estados && data.estados.filter(x => x.NOTA === "INGRESO EN SUCURSAL").length > 0 ? "-success" : ""}`}>
                  <img src={StatusAIcon} className="img-icon" alt="" />
                </div>
                <div className={`status-label${data.estados && data.estados.filter(x => x.NOTA === "INGRESO EN SUCURSAL").length > 0 ? "-success" : ""}`}>
                  INGRESO EN SUCURSAL
                </div>
                {
                  data.estados && data.estados.filter(x => x.NOTA === "INGRESO EN SUCURSAL").length > 0 &&
                  <div className="status-fecha">
                    <div className="status-fecha-dia">
                      <p className="p-status-dia">{parseInt(data.estados.filter(x => x.NOTA === "INGRESO EN SUCURSAL")[0].FECHA_INGRESO.split("T")[0].split("-")[2])}</p>
                    </div>
                    <div className="status-fecha-mes">
                      <p className="p-status-mes">
                        {`${mes(parseInt(data.estados.filter(x => x.NOTA === "INGRESO EN SUCURSAL")[0].FECHA_INGRESO.split("T")[0].split("-")[1]))} ${data.estados.filter(x => x.NOTA === "INGRESO EN SUCURSAL")[0].FECHA_INGRESO.split("T")[0].split("-")[0]}`}
                      </p>
                      <p className="p-status-sucursal">{data.estados.filter(x => x.NOTA === "INGRESO EN SUCURSAL")[0].SUCURSAL}</p>
                    </div>
                  </div>
                }
              </div>
              <div className="col-1-6">
                <div className={`status-icon${data.estados && data.estados.filter(x => x.NOTA === "EN PROCESO DE CARGA").length > 0 ? "-success" : ""} after`}>
                  <img src={StatusBIcon} className="img-icon" alt="" />
                </div>
                <div className={`status-label${data.estados && data.estados.filter(x => x.NOTA === "EN PROCESO DE CARGA").length > 0 ? "-success" : ""}`}>
                  EN PROCESO DE CARGA
                </div>
                {
                  data.estados && data.estados.filter(x => x.NOTA === "EN PROCESO DE CARGA").length > 0 &&
                  <div className="status-fecha">
                    <div className="status-fecha-dia">
                      <p className="p-status-dia">{parseInt(data.estados.filter(x => x.NOTA === "EN PROCESO DE CARGA")[0].FECHA_INGRESO.split("T")[0].split("-")[2])}</p>
                    </div>
                    <div className="status-fecha-mes">
                      <p className="p-status-mes">
                        {`${mes(parseInt(data.estados.filter(x => x.NOTA === "EN PROCESO DE CARGA")[0].FECHA_INGRESO.split("T")[0].split("-")[1]))} ${data.estados.filter(x => x.NOTA === "EN PROCESO DE CARGA")[0].FECHA_INGRESO.split("T")[0].split("-")[0]}`}
                      </p>
                      <p className="p-status-sucursal">{data.estados.filter(x => x.NOTA === "EN PROCESO DE CARGA")[0].SUCURSAL}</p>
                    </div>
                  </div>
                }
              </div>
              <div className="col-1-6">
                <div className={`status-icon${data.estados && data.estados.filter(x => x.NOTA === "EN TRANSITO").length > 0 ? "-success" : ""} after`}>
                  <img src={StatusCIcon} className="img-icon" alt="" />
                </div>
                <div className={`status-label${data.estados && data.estados.filter(x => x.NOTA === "EN TRANSITO").length > 0 ? "-success" : ""}`}>
                  EN TRÁNSITO
                </div>
                {
                  data.estados && data.estados.filter(x => x.NOTA === "EN TRANSITO").length > 0 &&
                  <div className="status-fecha">
                    <div className="status-fecha-dia">
                      <p className="p-status-dia">{parseInt(data.estados.filter(x => x.NOTA === "EN TRANSITO")[0].FECHA_INGRESO.split("T")[0].split("-")[2])}</p>
                    </div>
                    <div className="status-fecha-mes">
                      <p className="p-status-mes">
                        {`${mes(parseInt(data.estados.filter(x => x.NOTA === "EN TRANSITO")[0].FECHA_INGRESO.split("T")[0].split("-")[1]))} ${data.estados.filter(x => x.NOTA === "EN TRANSITO")[0].FECHA_INGRESO.split("T")[0].split("-")[0]}`}
                      </p>
                      <p className="p-status-sucursal">{data.estados.filter(x => x.NOTA === "EN TRANSITO")[0].SUCURSAL}</p>
                    </div>
                  </div>
                }
              </div>
              <div className="col-1-6">
                <div className={`status-icon${data.estados && data.estados.filter(x => x.NOTA === "EN PROCESO DE DESCARGA").length > 0 ? "-success" : ""} after`}>
                  <img src={StatusDIcon} className="img-icon" alt="" />
                </div>
                <div className={`status-label${data.estados && data.estados.filter(x => x.NOTA === "EN PROCESO DE DESCARGA").length > 0 ? "-success" : ""}`}>
                  EN PROCESO DE DESCARGA
                </div>
                {
                  data.estados && data.estados.filter(x => x.NOTA === "EN PROCESO DE DESCARGA").length > 0 &&
                  <div className="status-fecha">
                    <div className="status-fecha-dia">
                      <p className="p-status-dia">{parseInt(data.estados.filter(x => x.NOTA === "EN PROCESO DE DESCARGA")[0].FECHA_INGRESO.split("T")[0].split("-")[2])}</p>
                    </div>
                    <div className="status-fecha-mes">
                      <p className="p-status-mes">
                        {`${mes(parseInt(data.estados.filter(x => x.NOTA === "EN PROCESO DE DESCARGA")[0].FECHA_INGRESO.split("T")[0].split("-")[1]))} ${data.estados.filter(x => x.NOTA === "EN PROCESO DE DESCARGA")[0].FECHA_INGRESO.split("T")[0].split("-")[0]}`}
                      </p>
                      <p className="p-status-sucursal">{data.estados.filter(x => x.NOTA === "EN PROCESO DE DESCARGA")[0].SUCURSAL}</p>
                    </div>
                  </div>
                }
              </div>
              <div className="col-1-6">
                <div className={`status-icon${data.estados && data.estados.filter(x => x.NOTA === "ORDEN DESCARGADA").length > 0 ? "-success" : ""} after`}>
                  <img src={StatusEIcon} className="img-icon" alt="" />
                </div>
                <div className={`status-label${data.estados && data.estados.filter(x => x.NOTA === "ORDEN DESCARGADA").length > 0 ? "-success" : ""}`}>
                  EN BODEGA DESTINO
                </div>
                {
                  data.estados && data.estados.filter(x => x.NOTA === "ORDEN DESCARGADA").length > 0 &&
                  <div className="status-fecha">
                    <div className="status-fecha-dia">
                      <p className="p-status-dia">{parseInt(data.estados.filter(x => x.NOTA === "ORDEN DESCARGADA")[0].FECHA_INGRESO.split("T")[0].split("-")[2])}</p>
                    </div>
                    <div className="status-fecha-mes">
                      <p className="p-status-mes">
                        {`${mes(parseInt(data.estados.filter(x => x.NOTA === "ORDEN DESCARGADA")[0].FECHA_INGRESO.split("T")[0].split("-")[1]))} ${data.estados.filter(x => x.NOTA === "ORDEN DESCARGADA")[0].FECHA_INGRESO.split("T")[0].split("-")[0]}`}
                      </p>
                      <p className="p-status-sucursal">{data.estados.filter(x => x.NOTA === "ORDEN DESCARGADA")[0].SUCURSAL}</p>
                    </div>
                  </div>
                }
              </div>
              <div className="col-1-6">
                <div className={`status-icon${data.estados && data.estados.filter(x => x.NOTA === "ENTREGADO A CLIENTE").length > 0 ? "-success" : ""} after`}>
                  <img src={StatusFIcon} className="img-icon" alt="" />
                </div>
                <div className={`status-label${data.estados && data.estados.filter(x => x.NOTA === "ENTREGADO A CLIENTE").length > 0 ? "-success" : ""}`}>
                  ENTREGADO A CLIENTE
                </div>
                {
                  data.estados && data.estados.filter(x => x.NOTA === "ENTREGADO A CLIENTE").length > 0 &&
                  <div className="status-fecha">
                    <div className="status-fecha-dia">
                      <p className="p-status-dia">{parseInt(data.estados.filter(x => x.NOTA === "ENTREGADO A CLIENTE")[0].FECHA_INGRESO.split("T")[0].split("-")[2])}</p>
                    </div>
                    <div className="status-fecha-mes">
                      <p className="p-status-mes">
                        {`${mes(parseInt(data.estados.filter(x => x.NOTA === "ENTREGADO A CLIENTE")[0].FECHA_INGRESO.split("T")[0].split("-")[1]))} ${data.estados.filter(x => x.NOTA === "ENTREGADO A CLIENTE")[0].FECHA_INGRESO.split("T")[0].split("-")[0]}`}
                      </p>
                      <p className="p-status-sucursal">{data.estados.filter(x => x.NOTA === "ENTREGADO A CLIENTE")[0].SUCURSAL}</p>
                    </div>
                  </div>
                }
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-45">
                <div className="card">
                  <div className="card-row">
                    <p className="p-subtitle">
                      <img src={Arrow} className="right-arrow" alt="" />
                      DETALLES DEL ENVÍO
                    </p>
                    <br />
                  </div>
                  <div className="card-row-stripped">
                    <div className="card-col-left">
                      <p>RAZÓN SOCIAL</p>
                    </div>
                    <div className="card-col-right">
                      <p>{data.razon_social}</p>
                    </div>
                  </div>
                  <div className="card-row">
                    <div className="card-col-left">
                      <p>REMITENTE</p>
                    </div>
                    <div className="card-col-right">
                      <p>{data.remitente}</p>
                    </div>
                  </div>
                  <div className="card-row-stripped">
                    <div className="card-col-left">
                      <p>N° GUÍAS</p>
                    </div>
                    <div className="card-col-right">
                      <p>{data.numero_guia.join(", ")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-10">
              </div>
              <div className="col-45">
                <div className="card">
                  <div className="card-row">
                    <p className="p-subtitle">
                      <img src={Arrow} className="right-arrow" alt="" />
                      DETALLE DE COBRO N° {data.id_orden_cobro}
                    </p>
                    <br />
                  </div>
                  <div className="card-row-stripped">
                    <div className="card-col-left">
                      <p>PRECIO NETO</p>
                    </div>
                    <div className="card-col-right">
                      <p>$ {data.precio_neto.toLocaleString("es-CL")}</p>
                    </div>
                  </div>
                  <div className="card-row">
                    <div className="card-col-left">
                      <p>IVA</p>
                    </div>
                    <div className="card-col-right">
                      <p>$ {data.iva.toLocaleString("es-CL")}</p>
                    </div>
                  </div>
                  <hr className="hr-card" />  
                  <div className="card-row">
                    <div className="card-col-left">
                      <p className="p-total">TOTAL:</p>
                    </div>
                    <div className="card-col-right">
                      <p className="p-total">$ {data.total.toLocaleString("es-CL")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        
      </div>
      {
        loading &&
        <div className="loading">
          <img src={Logo} className="logo-loading" alt="" />
        </div>
      }
    </div>
  );
}

export default App;
